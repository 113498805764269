import {
  makeStyles,
  Typography,
  FormHelperText,
  MenuItem,
  Box,
  FormControlLabel,
  Checkbox,
  Button,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ContentTwoSided, LeftSide, RightSide } from "../core/ContentTwoSided";
import CotroliaFormControl from "../core/input/CotroliaFormControl";
import CotroliaInputLabel from "../core/input/CotroliaInputLabel";
import CotroliaSelect from "../core/input/CotroliaSelect";
import CotroliaTextField from "../core/input/CotroliaTextField";
import { CotroliaStepper, CotroliaStepperActions } from "../core/stepper";
import CotroliaAutocomplete from "../core/input/CotroliaAutocomplete";
import CotroliaAutocompleteFreeText from "../core/input/CotroliaAutocompleteFreeText";
import UploadButton from "../core/UploadButton";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import configurationService from "../../services/configuration.service";
import interventionService from "../../services/intervention.service";
import AppConstant from "../../utils/appConstant";

const useStyle = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
  },
  titleDesc: {
    color: theme.palette.text.secondary,
  },
  uploadContainer: {
    padding: `${theme.spacing(1.5)}px 0px 0px`,
  },
  mt: {
    marginTop: theme.spacing(2),
  },
}));

const emptyError = {
  error: false,
  msg: "",
};

const NewInterventionStepPart = ({
  values,
  updateValue,
  steps,
  activeStep,
  nextStep,
  previousStep,
  configuration,
  sharedData,
  updateSharedData
}) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const [partFrequency, setPartFrequency] = useState(null);
  const [partType, setPartType] = useState(null);
  const [typeError, setTypeError] = useState(emptyError);
  const [manufacturerError, setManufacturerError] = useState(emptyError);
  const [warrantyPrevInterError, setWarrantyPrevInterError] =
    useState(emptyError);
  const [refError, setRefError] = useState(emptyError);
  const [descriptionError, setDescriptionError] = useState(emptyError);
 
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const id = queryParams.get("id")
    if (id && (values.ref && (values.ref.id !== '' && values.ref.id !== null))) {
      configurationService.getComplementaryPartInstructionsRefSaved(values.ref.id)
        .then((res) => {
          updateSharedData(res);
        })
        .catch((error) => {
          console.error("Error fetching additional data:", error);
        });
    }
  }, []);

  useEffect(() => {    
    if (configuration) {
      if (configuration.partFrequency) {
        setPartFrequency(configuration.partFrequency);
      }
      if (configuration.partType) {
        setPartType(configuration.partType);
      }
    }
  }, [configuration]);

  const setParentFiles = (childrenFiles) => {
    updateValue("part", "pictures", childrenFiles);
  };

  const handleWarantyChange = (e) => {
    const value = e.target.value;
    updateValue("part", "waranty", value);

    // Clear the value of prev inter if the warranty value is not "yes"
    if (value === AppConstant.warrantyNo) {
      updateValue("part", "warrantyPrevInter", {
        id: null,
        label: "",
      });
    }
  };

  const hasErrors = () => {
    return (
      typeError.error ||
      manufacturerError.error ||
      refError.error ||
      descriptionError.error
    );
  };

  const handleClick = () => {
    let hasError = false;

    if (values.type === "") {
      setTypeError({
        error: true,
        msg: "RequiredFieldTypeError",
      });
      hasError = true;
    }

    // [#112] Errror handling for warranty prev intervention
    if (
      values.waranty === AppConstant.warrantyYes &&
      (values.warrantyPrevInter.id === undefined ||
        values.warrantyPrevInter.id === null)
    ) {
      setWarrantyPrevInterError({
        error: true,
        msg: "RequiredFieldWarrantyPrevInterError",
      });
      hasError = true;
    }

    if (
      values.manufacturer.id === undefined ||
      values.manufacturer.id === null
    ) {
      setManufacturerError({
        error: true,
        msg: "RequiredFieldManufacturerError",
      });
      hasError = true;
    }

    if (values.description === "" && configuration.sector !== "1") {
      setDescriptionError({
        error: true,
        msg: "RequiredFieldDescriptionError",
      });
      hasError = true;
    }

    if (values.ref.ref === null || values.ref.ref === "") {
      setRefError({
        error: true,
        msg: "RequiredFieldRefError",
      });
      hasError = true;
    }

    if (!hasError) {
      updateSharedData(sharedData)
      nextStep();
    }
  };

  return (
    <>
      <Typography variant="h4" className={classes.title} align="center">
        {t("YourBrokenPart")}
      </Typography>
      <Typography
        variant="subtitle1"
        className={classes.titleDesc}
        align="center"
      >
        {t("YourBrokenPartDesc")}
      </Typography>
      <CotroliaStepper steps={steps} activeStep={activeStep} />

      <ContentTwoSided>
        <LeftSide>
          {/* Waranty */}
          <CotroliaFormControl variant="filled" required>
            <CotroliaInputLabel id="waranty-label">
              {t("Waranty")}
            </CotroliaInputLabel>
            <CotroliaSelect
              id="waranty"
              labelId="waranty-label"
              aria-describedby="waranty-helper"
              value={values.waranty}
              onChange={handleWarantyChange}
            >
              <MenuItem value={1}>{t("Yes")}</MenuItem>
              <MenuItem value={2}>{t("No")}</MenuItem>
            </CotroliaSelect>
            <FormHelperText id="waranty-helper">
              {t("WarantyHelper")}
            </FormHelperText>
          </CotroliaFormControl>

          {/* Waranty Prev inter only displayed when we select warranty */}
          {values.waranty === AppConstant.warrantyYes && (
            <CotroliaFormControl
              variant="filled"
              required
              error={warrantyPrevInterError.error}
            >
              <CotroliaAutocomplete
                id="waranty-prev-inter"
                label={t("WarantyPrevInter")}
                value={values.warrantyPrevInter}
                setValue={(newValue) => {
                  if (newValue && newValue.id !== undefined) {
                    setWarrantyPrevInterError(emptyError);
                    updateValue("part", "warrantyPrevInter", newValue);

                    // On select, prefill part type, manufacturer and reference
                    interventionService
                      .getIntervention(newValue.id)
                      .then((res) => {
                        if (res.partType) {
                          updateValue("part", "type", res.partType.id);
                        }
                        if (res.partRef) {
                          updateValue("part", "ref", res.partRef);
                        }
                        if (res.manufacturer) {
                          updateValue("part", "manufacturer", res.manufacturer);
                        }
                      })
                      .catch((err) => {
                        console.error(
                          "Can’t get intervention information with id : " +
                            newValue.id
                        );
                      });
                  } else {
                    setWarrantyPrevInterError({
                      error: true,
                      msg: "RequiredFieldWarrantyPrevInterError",
                    });
                    updateValue("part", "warrantyPrevInter", {
                      id: null,
                      label: newValue,
                    });
                  }
                }}
                error={warrantyPrevInterError.error}
                getSuggestions={(value) =>
                  interventionService.listOldIntervention(value).then((res) => {
                    let ret = [];
                    Object.keys(res).forEach((key) => {
                      ret.push({ id: key, label: res[key] });
                    });
                    return ret;
                  })
                }
                required
                isSuggestionObject={true}
                labelKey="label"
              />
              <FormHelperText id="waranty-prev-inter-helper">
                {warrantyPrevInterError.msg !== ""
                  ? t(warrantyPrevInterError.msg)
                  : t("WarantyPrevInterHelper")}
              </FormHelperText>
            </CotroliaFormControl>
          )}

          {/* Part type */}
          <CotroliaFormControl
            variant="filled"
            required
            error={typeError.error}
          >
            <CotroliaInputLabel id="type-label">
              {t("PartType")}
            </CotroliaInputLabel>
            <CotroliaSelect
              id="type"
              labelId="type-label"
              value={values.type}
              native
              onChange={(e) => {
                if (e.target.value !== "") {
                  setTypeError(emptyError);
                } else {
                  setTypeError({
                    error: true,
                    msg: "RequiredFieldTypeError",
                  });
                }
                updateValue("part", "type", e.target.value);
              }}
            >
              <option value="" />
              {partType &&
                Object.keys(partType)
                  .sort((a, b) => partType[a].label.localeCompare(partType[b].label))
                  .map((key) => (
                    <optgroup label={partType[key].label} key={key}>
                      {partType[key].values &&
                        Object.keys(partType[key].values)
                          .sort((subkey1, subkey2) => partType[key].values[subkey1].localeCompare(partType[key].values[subkey2]))
                          .map((subkey) => (
                            <option key={subkey} value={subkey}>
                              {partType[key].values[subkey]}
                            </option>
                          ))}
                    </optgroup>
                  ))}
            </CotroliaSelect>
            <FormHelperText variant="outlined">
              {t(typeError.msg)}
            </FormHelperText>
          </CotroliaFormControl>

          {/* Manufacturer */}
          <CotroliaFormControl error={manufacturerError.error}>
            <CotroliaAutocomplete
              id="manufacturer"
              label={t("PartManufacturer")}
              value={values.manufacturer}
              error={manufacturerError.error}
              setValue={(newValue) => {
                if (newValue && newValue.id !== undefined) {
                  setManufacturerError(emptyError);
                  updateValue("part", "manufacturer", newValue);
                } else {
                  setManufacturerError({
                    error: true,
                    msg: "RequiredFieldManufacturerError",
                  });
                  updateValue("part", "manufacturer", {
                    id: "",
                    label: newValue,
                  });
                }
              }}
              getSuggestions={(value) =>
                configurationService
                  .getDictionary("c_supercotrolia_manufacturer", "label", value)
                  .then((res) => {
                    let ret = [];
                    Object.keys(res).forEach((key) => {
                      ret.push({ id: key, label: res[key] });
                    });
                    return ret;
                  })
              }
              required
              isSuggestionObject={true}
              labelKey="label"
            />
            <FormHelperText variant="outlined">
              {t(manufacturerError.msg)}
            </FormHelperText>
          </CotroliaFormControl>

          {/* Reference */}
          <CotroliaFormControl error={refError.error}>
            <CotroliaAutocompleteFreeText
              id="ref"
              label={t("PartRef")}
              value={values.ref}
              error={refError.error}
              
              setValue={(newValue) => {
                if (newValue && newValue.id !== undefined) {
                  setRefError(emptyError);
                  updateValue("part", "ref", newValue);
                  configurationService.getTableDatas("supercotrolia_part", "instructions", newValue.id)
                    .then((res) => {
                      sharedData = res;
                      updateSharedData(sharedData)
                    })
                    .catch((error) => {
                      console.error("Error fetching additional data:", error);
                    });
                } else {
                  sharedData = null;
                  updateSharedData(sharedData)
                  setRefError(emptyError);
                  updateValue("part", "ref", {
                    id: "",
                    ref: newValue,
                  });
                }
              
              }}
              getSuggestions={(value) =>
                configurationService
                  .getComplementaryPart("supercotrolia_part_complementary", value)
                  .then((res) => {
                    let ret = [];
                    Object.keys(res).forEach((key) => {
                      ret.push({ id: key, ref: res[key] });
                    });
                    return ret;
                  })
              }
              required
              isSuggestionObject={true}
              labelKey="ref"
            />
            {refError.error && (
              <FormHelperText variant="outlined">
                {t(refError.msg)}
              </FormHelperText>)}
          </CotroliaFormControl>
        </LeftSide>

        <RightSide>
          {/* Description */}
          {configuration.sector !== "1" && (
          <CotroliaFormControl error={descriptionError.error}>
            <CotroliaTextField
              id="description"
              label={t("PartDescription")}
              value={values.description}
              onChange={(e) => {
                if (e.target.value !== "") {
                  setDescriptionError(emptyError);
                } else {
                  setDescriptionError({
                    error: true,
                    msg: "RequiredFieldDescriptionError",
                  });
                }
                updateValue("part", "description", e.target.value);
              }}
              required
              multiline
              rows={4}
              error={descriptionError.error}
            />
            <FormHelperText variant="outlined">
              {descriptionError.error
                ? t(descriptionError.msg)
                : t("PartDescriptionHelper")}
            </FormHelperText>
          </CotroliaFormControl>
          )}

          {/* Frequency */}
          {configuration.sector !== "1" && (
            <CotroliaFormControl variant="filled">
            <CotroliaInputLabel id="frequency-label">
              {t("PartFrequency")}
            </CotroliaInputLabel>
            <CotroliaSelect
                id="frequency"
                labelId="frequency-label"
                value={values.frequency}
                onChange={(e) => {
                  updateValue("part", "frequency", e.target.value);
                }}
            >
                {partFrequency &&
                  Object.keys(partFrequency).map((key) => (
                    <MenuItem value={key} key={key}>
                      {partFrequency[key]}
                    </MenuItem>
                  ))
                }
            </CotroliaSelect>
          </CotroliaFormControl>)}

          {/* Client ref */}
          <CotroliaFormControl>
            <CotroliaTextField
              id="clientRef"
              label={t("PartClientRef")}
              value={values.clientRef}
              onChange={(e) => {
                updateValue("part", "clientRef", e.target.value);
              }}
            />
            <FormHelperText variant="outlined">
              {t("PartClientRefHelper")}
            </FormHelperText>
          </CotroliaFormControl>

          {/* Upload document */}
          <Box className={classes.uploadContainer}>
            <Typography variant="body1">{t("DocumentOrPicture")}</Typography>
            <Typography variant="caption">
              {t("DocumentOrPictureHelper")}
            </Typography>
            <Box className={classes.mt}>
              <UploadButton
                parentFiles={values.pictures}
                setParentFiles={setParentFiles}
              />
            </Box>
          </Box>
        </RightSide>
      </ContentTwoSided>
      <CotroliaStepperActions>
        
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => {
            previousStep();
          }}
          disabled={steps[activeStep] === steps[0]} // We disable the back button if we are in the first step
        >
          {t("GoBack")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          endIcon={<ArrowForwardIcon />}
          onClick={handleClick}
          disabled={hasErrors()}
        >
          {t("Continue")}
        </Button>
      </CotroliaStepperActions>
    </>
  );
};

export default NewInterventionStepPart;

