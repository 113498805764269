import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import authService from "../services/auth.service";
import { useConfiguration } from "./configuration-context";

const AuthContext = React.createContext();

const AuthProvider = (props) => {
  const [user, setUser] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const alert = useAlert();
  const { i18n } = useTranslation();
  const { loading, fetchConfiguration } = useConfiguration();

  const fetchConnectedUser = async () => {
    setLoading(true);
    try {
      const userConnected = await authService.getMe();
      // [#178] Change connected user language
      if (
        userConnected.data.language &&
        i18n.language !== userConnected.data.language
      ) {
        await i18n.changeLanguage(userConnected.data.language);
        await fetchConfiguration(); // Refetch configuration in the user language
      }
      setUser(userConnected);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setUser(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!loading) {
      fetchConnectedUser();
    }
  }, [loading]);

  // Login
  const login = async (email, password) => {
    try {
      setLoading(true);
      await authService.login(email, password);
      // Reload the page
      window.location.reload(false);
    } catch (err) {
      setLoading(false);
      let errMsg = t("InternalError");
      if (err.response && err.response.status) {
        const status = err.response.status;
        if (status === 403) {
          // Access denied
          errMsg = t("WrongLoginOrPassword");
        }
      }

      alert.show(errMsg, {
        title: t("Error"),
        close: () => {
          alert.remove(alert);
        },
        type: "error",
      });
    }
  };

  // Logout
  const logout = async () => {
    try {
      await authService.logout();
      setUser(null);
    } catch (err) {
      // TODO : handle errors
      console.error(err);
    }
  };

  const updateUser = (user) => {
    setUser(user);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoading,
        login,
        logout,
        fetchConnectedUser,
        updateUser,
      }}
      {...props}
    />
  );
};

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
