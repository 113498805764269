import {
  makeStyles,
  Typography,
  FormHelperText,
  Button,
  MenuItem,
} from "@material-ui/core";

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ContentTwoSided, LeftSide, RightSide } from "../core/ContentTwoSided";
import CotroliaFormControl from "../core/input/CotroliaFormControl";
import CotroliaInputLabel from "../core/input/CotroliaInputLabel";
import CotroliaSelect from "../core/input/CotroliaSelect";
import CotroliaTextField from "../core/input/CotroliaTextField";
import { CotroliaStepper, CotroliaStepperActions } from "../core/stepper";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CotroliaAutocomplete from "../core/input/CotroliaAutocomplete";
import configurationService from "../../services/configuration.service";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
  },
  titleDesc: {
    color: theme.palette.text.secondary,
  },
  uploadContainer: {
    padding: `${theme.spacing(1.5)}px 0px 0px`,
  },
  mt: {
    marginTop: theme.spacing(2),
  },

  input: {
    "& > .MuiFilledInput-root": {
      borderRadius: "10px"
    },
    "& .MuiInputBase-root": {
      "&.Mui-disabled": {
        backgroundColor: "grey(0.5)"
      },
    },
    "& label": {
      color: "#276728"
    },
    "& .MuiFilledInput-root": {
      backgroundColor: "#f2f3f0"
    },
    "&::hover": {
      backgroundColor: "#f2f3f0"
    },
    "& .MuiFilledInput-underline:before": {
      display: "none"
    },
    "& .MuiFilledInput-underline.Mui-disabled:before": {
      display: "none"
    },

  }
}));

const emptyError = {
  error: false,
  msg: "",
};

const NewInterventionStepCar = ({
  values,
  updateValue,
  steps,
  activeStep,
  nextStep,
  previousStep,
  configuration,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [carBrandError, setCarBrandError] = useState(emptyError);
  const [carModelError, setCarModelError] = useState(emptyError);
  const [frameError, setFrameError] = useState(emptyError);
  const [registrationError, setRegistrationError] = useState(emptyError);
  const [distanceError, setDistanceError] = useState(emptyError);
  const [customerEffectError, setCustomerEffectError] = useState(emptyError);
  const [diagnosticError, setDiagnosticError] = useState(emptyError);
  const [partsReplacedError, setPartsReplacedError] = useState(emptyError);
  const [partFrequency, setPartFrequency] = useState(null);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (configuration) {
      if (configuration.partFrequency) setPartFrequency(configuration.partFrequency);
    }}, [configuration])

  useEffect(() => {
    
    if (values.car.carBrand.id) setDisabled(false)
    if (!values.car.carBrand.id) {
      values.car.carModel = "";
      updateValue("car", "carModel", { id: "", label: "" })
      setDisabled(true);
    }
  }, [values.car.carBrand])

  const hasErrors = () => {
    return (
      frameError.error ||
      carBrandError.error ||
      carModelError.error ||
      distanceError.error ||
      registrationError.error ||
      customerEffectError.error ||
      diagnosticError.error ||
      partsReplacedError.error
    );
  };

  const handleClick = () => {
    let hasError = false;
    
    if (values.car.frame === "") {
      setFrameError({
        error: true,
        msg: "RequiredFieldFrameError",
      });
      hasError = true;
    }
    
    if (values.car.distance === "") {
      setDistanceError({
        error: true,
        msg: "RequiredFieldDistanceError",
      });
      hasError = true;
    }

    if (values.car.registration === "") {
      setRegistrationError({
        error: true,
        msg: "RequiredFieldRegistrationError",
      });
      hasError = true;
    }

    if (values.car.customer_effect === "") {
      setCustomerEffectError({
        error: true,
        msg: "RequiredFieldCustomerEffectError",
      });
      hasError = true;
    }

    if (values.car.diagnostic === "") {
      setDiagnosticError({
        error: true,
        msg: "RequiredFieldDiagnosticError",
      });
      hasError = true;
    }

    if (values.car.parts_replaced === "") {
      setPartsReplacedError({
        error: true,
        msg: "RequiredFieldPartsReplacedError",
      });
      hasError = true;
    }

    if (values.car.carBrand.id === "" || values.car.carBrand.id === null) {
      setCarBrandError({
        error: true,
        msg: "RequiredFieldCarBrandError",
      });
      hasError = true;
    }

    if (values.car.carModel.id === "" || values.car.carModel.id === null) {
      setCarModelError({
        error: true,
        msg: "RequiredFieldCarModelError",
      });
      hasError = true;
    }

    if (!hasError) {
      nextStep();
    }
  };
  return (
    <>
      <Typography variant="h4" className={classes.title} align="center">
        {t("YourBrokenCar")}
      </Typography>
      <Typography
        variant="subtitle1"
        className={classes.titleDesc}
        align="center"
      >
        {t("YourBrokenCarDesc")}
      </Typography>
      <CotroliaStepper steps={steps} activeStep={activeStep} />

      <ContentTwoSided>
        <LeftSide>
          {/* Frame */}
         <CotroliaFormControl error={frameError.error}>
            <CotroliaTextField
              id="frame"
              label={t("Frame")}
              inputProps={{ maxLength: 17 }}
              maxLength={5}
              value={values.car.frame}
              onChange={(e) => {
                if (e.target.value !== "" && e.target.value.length === 17) {
                  setFrameError(emptyError);
                } else {
                  setFrameError({
                    error: false,
                    msg: "RequiredFieldFrameError",
                  });
                }
                updateValue("car", "frame", e.target.value);
              }}
              required
              rows={1}
              error={frameError.error}
            />
          </CotroliaFormControl>

          {/* Car Brand */}
          <CotroliaFormControl error={carBrandError.error}>
            <CotroliaAutocomplete
              id="brand-car-label"
              label={t("CarBrand")}
              value={values.car.carBrand}
              error={carBrandError.error}
              setValue={(newValue) => {
                if (newValue && newValue.id !== undefined) {
                  setCarBrandError(emptyError);
                  updateValue("car", "carBrand", newValue);
                  updateValue("car", "carModel", { id: "", label: "" }); // Make model value to empty if the user delete the carBrand, to fetch new carBrand ID and list new models associated
                  setDisabled(false)
                } else {
                  setCarBrandError({
                    error: false,
                    msg: "RequiredFieldCarBrandError",
                  });
                  updateValue("car", "carBrand", {
                    id: "",
                    label: newValue,
                  });
                  // updateValue("car", "carModel", { id: "", label: "" });
                  setDisabled(true)
                }
              }}
              getSuggestions={(value) =>
                configurationService
                  .getDictionaryParentOrChild("c_supercotrolia_brand_model", "label", value, 0)
                  .then((res) => {
                    let ret = [];
                    Object.keys(res).forEach((key) => {
                      ret.push({ id: key, label: res[key] });
                    });
                    return ret;
                  })
              }
              displayList={true}
              required
              isSuggestionObject={true}
              labelKey="label"
            />
            <FormHelperText variant="outlined">
              {t(carBrandError.msg)}
            </FormHelperText>
          </CotroliaFormControl>
          
          {/* Car Model */}
            <CotroliaFormControl error={carModelError.error}>
            <CotroliaAutocomplete
              className={disabled ? classes.input : classes.input}
              id="model-car-label"
              label={t("CarModel")}
              value={values.car.carModel}
              error={carModelError.error}
              setValue={(newValue) => {
                if (newValue && newValue.id !== undefined) {
                  setCarModelError(emptyError);
                  updateValue("car", "carModel", newValue);
                } else {
                  setCarModelError({
                    error: false,
                    msg: "RequiredFieldCarModelError",
                  });
                  updateValue("car", "carModel", {
                    id: "",
                    label: newValue,
                  });
                }
              }}
              getSuggestions={(value) =>
                configurationService
                  .getDictionaryParentOrChild("c_supercotrolia_brand_model", "label", value, values.car.carBrand.id)
                  .then((res) => {
                    let ret = [];
                    Object.keys(res).forEach((key) => {
                      ret.push({ id: key, label: res[key] });
                    });
                    return ret;
                  })
              }
              displayList={true}
              disabled={disabled}
              required
              isSuggestionObject={true}
              labelKey="label"
                />
              <FormHelperText variant="outlined">
                {t(carModelError.msg)}
              </FormHelperText>
            </CotroliaFormControl>
          

          {/* Distance */}
          <CotroliaFormControl error={distanceError.error}>
            <CotroliaTextField
              id="distance"
              label={t("Distance")}
              value={values.car.distance}
              onChange={(e) => {
                if (e.target.value !== "") {
                  setDistanceError(emptyError);
                } else {
                  setDistanceError({
                    error: true,
                    msg: "RequiredFieldDistanceError",
                  });
                }
                updateValue("car", "distance", e.target.value);
              }}
              required
              type={"number"}
              error={distanceError.error}
            />
          </CotroliaFormControl>

          {/* Registration */}
          <CotroliaFormControl error={registrationError.error}>
            <CotroliaTextField
              id="registration"
              label={t("Registration")}
              value={values.car.registration}
              onChange={(e) => {
                if (e.target.value !== "") {
                  setRegistrationError(emptyError);
                } else {  
                  setRegistrationError({
                    error: true,
                    msg: "RegisterErrorMissingRequiredFields",
                  });
                }
                
                updateValue("car", "registration", e.target.value);
              }}
              required
              error={registrationError.error}
            />
          </CotroliaFormControl>

          {/* Security code */}
            <CotroliaFormControl>
              <CotroliaTextField
                id="securitycode"
                label={t("SecurityCodeCotrolia")}
                value={values.car.security_code}
                onChange={(e) => { updateValue("car", "security_code", e.target.value); }}
              />
            </CotroliaFormControl>
        </LeftSide>

        <RightSide>
         {/* Frequency */}
         <CotroliaFormControl variant="filled">
             <CotroliaInputLabel id="frequency-label">
                 {t("PartFrequency")}
             </CotroliaInputLabel>
             <CotroliaSelect
                 id="frequency"
                 labelId="frequency-label"
                 value={values.part.frequency}
                 onChange={(e) => {
                     updateValue("part", "frequency", e.target.value);
                 }}
             >
                 {partFrequency &&
                     Object.keys(partFrequency).map((key) => (
                         <MenuItem value={key} key={key}>
                             {partFrequency[key]}
                         </MenuItem>
                     ))}
             </CotroliaSelect>
          </CotroliaFormControl>
          
          
          {/* Customer Effect */}
          {(configuration.sector === "1" &&
            <CotroliaFormControl error={customerEffectError.error}>
              <CotroliaTextField
                id="customer_effect"
                label={t("CarCustomerEffectCotrolia")}
                value={values.car.customer_effect}
                onChange={(e) => {
                  if (e.target.value !== "") {
                    setCustomerEffectError(emptyError);
                  } else {
                    setCustomerEffectError({
                      error: true,
                      msg: "RequiredFieldCustomerEffectError",
                    });
                  }
                  updateValue("car", "customer_effect", e.target.value);
                }}
                required
                multiline
                rows={4}
                error={customerEffectError.error}
              />
            </CotroliaFormControl>
          )}

          {/* Diagnostic */}
            <CotroliaFormControl error={diagnosticError.error}>
              <CotroliaTextField
                id="diagnostic"
                label={t("DiagnosticCotrolia")}
                value={values.car.diagnostic}
                onChange={(e) => {
                  if (e.target.value !== "") {
                    setDiagnosticError(emptyError);
                  } else {
                    setDiagnosticError({
                      error: true,
                      msg: "RequiredFieldDiagnosticError",
                    });
                  }
                  updateValue("car", "diagnostic", e.target.value);
                }}
                required
                multiline
                rows={4}
                error={diagnosticError.error}
              />
            </CotroliaFormControl>

          {/* Parts Replaced */}
          <CotroliaFormControl error={partsReplacedError.error}>
            <CotroliaTextField
              id="parts-replaced"
              label={t("PartsReplaced")}
              value={values.car.parts_replaced}
              onChange={(e) => {
                if (e.target.value !== "") {
                  setPartsReplacedError(emptyError);
                } else {
                  setPartsReplacedError({
                    error: true,
                    msg: "RequiredFieldPartsReplacedError",
                  });
                }
                updateValue("car", "parts_replaced", e.target.value);
              }}
              required
              multiline
              rows={4}
              error={partsReplacedError.error}
            />
          </CotroliaFormControl> 
        </RightSide>
      </ContentTwoSided>
      <CotroliaStepperActions>
        
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => {
            previousStep();
          }}
          disabled={steps[activeStep] === steps[0]} // We disable the back button if we are in the first step
        >
          {t("GoBack")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          endIcon={<ArrowForwardIcon />}
          onClick={handleClick}
          disabled={hasErrors()}
        >
          {t("Continue")}
        </Button>
      </CotroliaStepperActions>
    </>
  );
};

export default NewInterventionStepCar;

