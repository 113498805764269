import {
  makeStyles,
  Typography,
  Box,
  Button,
  FormHelperText,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ContentTwoSided, LeftSide, RightSide } from "../core/ContentTwoSided";
import CotroliaFormControl from "../core/input/CotroliaFormControl";
import { CotroliaStepper, CotroliaStepperActions } from "../core/stepper";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import AppConstant from "../../utils/appConstant";
import HTMLContainer from "../core/HTMLContainer";
import configurationService from "../../services/configuration.service";
import { size } from "lodash";

const useStyle = makeStyles((theme) => ({
  label: {
    color: theme.palette.primary.main,
  },
  title: {
    color: theme.palette.text.primary,
  },
  info: {
    color: theme.palette.info.main,
  },
  titleDesc: {
    color: theme.palette.text.secondary,
  },
  inputBackground: {
    width: "100%",
    borderRadius: theme.spacing(1) + 2,
    backgroundColor: theme.palette.grey[50],
    "& > *": {
      padding: theme.spacing(1.5),
    },
  },
  scrollbarPackaging: {
    overflowY: "scroll",
    maxHeight: "400px",
    "&::-webkit-scrollbar": {
      width: "10px",
      height: "100%"
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      borderRadius: "100vh",
      background: theme.palette.grey[50],
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.primary.main,
      borderRadius: "100vh",
    }
  },

  scrollbarComplementaryPart: {
    overflowY: "scroll",
    maxHeight: "200px",
    "&::-webkit-scrollbar": {
      width: "10px",
      height: "100%"
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      borderRadius: "100vh",
      background: theme.palette.grey[50],
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.primary.main,
      borderRadius: "100vh",
    }
  },
  toggleButton: {
    width: "100%",
    "& .MuiToggleButton-root": {
      flex: 1,
    },
    "& .MuiToggleButton-root.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));

const emptyError = {
  error: false,
  msg: "",
};

const NewInterventionStepPackaging = ({
  values,
  updateValue,
  steps,
  activeStep,
  nextStep,
  previousStep,
  configuration,
  shippingMethod,
  sharedData,
}) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const [instructionsError, setInstructionsError] = useState(emptyError);
  const [ComplementaryPartError, setComplementaryPartError] = useState(emptyError);
  const displayAddress = shippingMethod !== AppConstant.shippingMethodPicking;

  const hasErrors = () => {
    if (sharedData !== null) {
      return instructionsError.error, ComplementaryPartError.error;
    } else if (sharedData == null && configuration.sector == "1") {
      return instructionsError.error, ComplementaryPartError.error;
    } else {
      return instructionsError.error;
    }
  };

  const handleClick = () => {
    let hasError = false;

    if (values.instructions === false) {
      setInstructionsError({
        error: true,
        msg: "RequiredFieldInstructionsError",
      });
      hasError = true;
    }

    if (sharedData !== null) {
      if (values.complementaryPart === false) {
        setComplementaryPartError({
          error: true,
          msg: "RequiredFieldComplementaryPartError",
        });
        hasError = true;
      }
    } else if (sharedData == null && configuration.sector == "1") {
      if (values.complementaryPart === false) {
        setComplementaryPartError({
          error: true,
          msg: "RequiredFieldComplementaryPartError",
        });
        hasError = true;
      }
    }

    if (!hasError) {
      nextStep();
    }
  };


  return (
    <>
      <Typography variant="h4" className={classes.title} align="center">
        {t("StepPackagingTitle")}
      </Typography>
      <Typography
        variant="subtitle1"
        className={classes.titleDesc}
        align="center"
      >
        {t("StepPackagingDesc")}
      </Typography>
      <CotroliaStepper steps={steps} activeStep={activeStep} />

      <ContentTwoSided>
        <LeftSide>
          {/* Part number */}
          <CotroliaFormControl
            additionalClass={classes.inputBackground}
            required
          > 
             {configuration.sector !== "1" ? (
                <FormLabel className={classes.label}>{t("PartNumber")}</FormLabel>
              ) : (
                <FormLabel className={classes.label}>{t("PartNumberCotrolia")}</FormLabel>
              )}
            <FormGroup>
              <ToggleButtonGroup
                value={values.partNb.toString()}
                exclusive
                onChange={(e, value) => {
                  if (value !== null) {
                    updateValue("packaging", "partNb", value);
                  }
                }}
                className={classes.toggleButton}
              >
                <ToggleButton value="1">1</ToggleButton>
                <ToggleButton value="2">2</ToggleButton>
                <ToggleButton value="3">3</ToggleButton>
                <ToggleButton value="4">4</ToggleButton>
                <ToggleButton value="5">5</ToggleButton>
                <ToggleButton value="6">6</ToggleButton>
              </ToggleButtonGroup>
            </FormGroup>
          </CotroliaFormControl>
          {/* Entity address for expedition */}
          {displayAddress && (
            <CotroliaFormControl additionalClass={classes.inputBackground}>
              <FormLabel className={classes.label}>
                {t("ExpeditionAddress")}
              </FormLabel>
              <Typography>{configuration.entityAddress}</Typography>
            </CotroliaFormControl>
          )}
          {/* Complementary Part infos*/}
          { (sharedData != null)  && (
            <CotroliaFormControl
              className={classes.inputBackground}
              error={ComplementaryPartError.error}
              style={{ marginBottom: 8 }}
            >
              <FormLabel className={classes.label}>
                {t("ComplementaryPart") + " : "}
              </FormLabel>
              <Box className={classes.scrollbarComplementaryPart} >
                <ul className="custom-list">
                  <li> {sharedData} </li>
                </ul>
                <FormControlLabel
                  
                  label={t("AcceptComplementaryPart")}
                  control={
                    <Checkbox
                      color="primary"
                      checked={values.complementaryPart}
                      onChange={() => {
                        if (!values.complementaryPart === true) {
                          setComplementaryPartError(emptyError);
                        } else {
                          setComplementaryPartError({
                            error: true,
                            msg: "RequiredFieldComplementaryPartError",
                          });
                        }
                        updateValue(
                          "packaging",
                          "complementaryPart",
                          !values.complementaryPart
                        );
                      }}
                    />
                  }
                />
              </Box>
              <FormHelperText variant="outlined">
                {t(ComplementaryPartError.msg)}
              </FormHelperText>
            </CotroliaFormControl>
          )}

          {(sharedData == null) && (configuration.sector === "1")  && (
            <CotroliaFormControl
              className={classes.inputBackground}
              error={ComplementaryPartError.error}
              style={{ marginBottom: 8 }}
            >
              <FormLabel className={classes.label}>
                {t("ComplementaryPart") + " : "}
              </FormLabel>
              <Box className={classes.scrollbarComplementaryPart} >
                <ul className="custom-list">
                  <li dangerouslySetInnerHTML={{ __html: t("NoComplementaryPart") }}></li>
                </ul>
                <FormControlLabel
                  
                  label={t("AcceptComplementaryPart")}
                  control={
                    <Checkbox
                      color="primary"
                      checked={values.complementaryPart}
                      onChange={() => {
                        if (!values.complementaryPart === true) {
                          setComplementaryPartError(emptyError);
                        } else {
                          setComplementaryPartError({
                            error: true,
                            msg: "RequiredFieldComplementaryPartError",
                          });
                        }
                        updateValue(
                          "packaging",
                          "complementaryPart",
                          !values.complementaryPart
                        );
                      }}
                    />
                  }
                />
              </Box>
              <FormHelperText variant="outlined">
                {t(ComplementaryPartError.msg)}
              </FormHelperText>
            </CotroliaFormControl>
          )}
        </LeftSide>

        
        <RightSide>
          {/* Packing instructions*/}
          <CotroliaFormControl
            className={classes.inputBackground}
            error={instructionsError.error}
          >
            <FormLabel className={classes.label}>
              {t("PackingInstructions") + " : "}
            </FormLabel>
            <Box className={classes.scrollbarPackaging}>
              <HTMLContainer>{configuration.packingInstruction}</HTMLContainer>
              <FormControlLabel
                label={t("AcceptPackingInstructions")}
                control={
                  <Checkbox
                    color="primary"
                    checked={values.instructions}
                    onChange={() => {
                      if (!values.instructions === true) {
                        setInstructionsError(emptyError);
                      } else {
                        setInstructionsError({
                          error: true,
                          msg: "RequiredFieldInstructionsError",
                        });
                      }
                      updateValue(
                        "packaging",
                        "instructions",
                        !values.instructions
                      );
                    }}
                  />
                }
              />
            </Box>
            <FormHelperText variant="outlined">
              {t(instructionsError.msg)}
            </FormHelperText>
            </CotroliaFormControl>
          
        </RightSide>
      </ContentTwoSided>
      <CotroliaStepperActions>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => {
            previousStep();
          }}
          disabled={steps[activeStep] === steps[0]} // We disable the back button if we are in the first step
        >
          {t("GoBack")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          endIcon={<ArrowForwardIcon />}
          onClick={handleClick}
          disabled={hasErrors()}
        >
          {t("Continue")}
        </Button>
      </CotroliaStepperActions>
    </>
  );
};

export default NewInterventionStepPackaging;


